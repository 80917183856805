import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import callAPI from "../../../../../api/apiCaller";
import Button from "../../../../../common/Button";
import { ButtonSpacer } from "../../../../../common/Button/styles";
import { Context } from "../../../../../Store";
import { refreshProgramList } from "../../../../../Store/ProgramsList/actions";
import EditProgramManagers from "../../popups/EditProgramManagers";
import SendAnnouncement from "../../popups/SendAnnouncement";
import Announcements from "./Announcements";
import {
  ProgramHomeContainer,
  ProgramHomeHeader,
  ProgramImage,
  ProgramInfo,
  ProgramManagerImage,
  ProgramManagers,
  ProgramManagersLabel,
  ProgramManagersList,
  ProgramName,
  ProgramOrganization,
  ProgramTrackedHours,
  AboutContent,
  AboutTitle,
  AboutDescription,
  AnnouncementsContent,
  AnnouncementsTitle,
  EditModeOptions,
  LinkLine,
  Link,
  EditDropdown,
  EditButton,
  EditOptionsOuter,
  PublishAnnouncement,
  AddAnnouncement,
  SurvyListButton,
} from "./styles";
import ApplicationEditorModal from "../../popups/ApplicationEditorModal";
import features from "../../../../../config/features";
import EditProgram from "../../popups/EditProgram";
import AddParticipants from "../../popups/AddParticipants";
import ApplicationModal from "../../popups/ApplicationModal";
import {
  convertToLinks,
  formatDescription,
  formatsDescription,
} from "../../../../../common/utils";
import { getUserInfo } from "../../../../../Store/User/accessors";
import Modal from "../../../../../common/Modal";
import { SectionActions } from "../../../Profile/LoginSecurity/styles";
import NewRequestForm from "../NewRequest";
import Payment from "./Payment";
import Icon from "../../../../../common/Icon";
import SurveyListModal from "../ProgramSurveys/modals/SurveyListsModal";

const ADMIN_ROLES = [
  "manager",
  "member_manager",
  "mentor_manager",
  "mentee_manager",
];

const SURVEY_ROLES =[
  'mentor','mentee'
]
function ProgramHome({ program, refreshProgram }) {
  let params = useParams();
     const programId = +params?.selectedProgram;
  let navigate = useNavigate();
   const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [showEditProgram, setShowEditProgram] = React.useState(false);
  const [showEditProgramManagers, setShowEditProgramManagers] =
    React.useState(false);
  const [isDeletingProgram, setIsDeletingProgram] = React.useState(false);
  const [showSendAnnouncement, setShowSendAnnouncement] = React.useState(false);
  const [showApplicationEditor, setShowApplicationEditor] =
    React.useState(false);
  const editMode = ADMIN_ROLES.includes(program.role);
  const showSurveyButton = SURVEY_ROLES.includes(program.role);
  const { state, dispatch } = React.useContext(Context);
  const [isAddParticipantsOpen, setIsAddParticipantsOpen] =
    React.useState(false);
  const [hasCopied, setHasCopied] = React.useState(false);
  const [isApplicationOpen, setIsApplicationOpen] = React.useState(false);
  const [isApplicationOpenforApply, setIsApplicationOpenforApply] =
    React.useState(false);
  const [isSuperCharge, setIsSuperCharge] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [openSurveyListModal, setOpenSurveyListModal] = React.useState(false);
 

  function deleteProgram() {
    setIsDeletingProgram(true);
    callAPI(dispatch, "deleteProgram", { id: params.selectedProgram }).then(
      () => {
        setIsDeletingProgram(false);
        navigate("/programs");
        dispatch(refreshProgramList());
      }
    );
  }

  function closeModal() {
    setIsModalOpen(false);
  }

  let url =
    window.location.protocol +
    "//" +
    window.location.host +
    "/app/programs/" +
    program.id +
    "/home";
  return (
    <ProgramHomeContainer>
      <ProgramHomeHeader>
        <ProgramImage src={program.image} />
        <ProgramInfo>
          <ProgramName>{program.title}</ProgramName>
          <ProgramOrganization>{program.organization}</ProgramOrganization>
          <ProgramManagers>
            <ProgramManagersLabel>Program Managers</ProgramManagersLabel>
            <ProgramManagersList>
              {program.managers.map((manager) => {
                return (
                  <>
                    <ProgramManagerImage
                      key={manager.id}
                      src={
                        manager.user.image
                          ? manager.user.image
                          : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                      }
                      onClick={() => {
                        getUserInfo(state).id === manager.userId
                          ? navigate(`/profile/account`)
                          : navigate(`/messages/${manager.user.uuid}`);
                      }}
                    />
                    <ButtonSpacer />
                  </>
                );
              })}
            </ProgramManagersList>
          </ProgramManagers>
          {program.programType !== "TEAM" && (
            <ProgramTrackedHours>
              Total Tracked Hours: {program.totalTrackHours}
            </ProgramTrackedHours>
          )}
          <LinkLine>
            <Link href={url}>{url}</Link>
            <Button
              icon={hasCopied ? "tick" : "copy"}
              text="Copy"
              onClick={() => {
                navigator.clipboard.writeText(url);
                setHasCopied(true);
              }}
            />
          </LinkLine>
          {
            <>
            

              {
                // program.programType==="1_1_NETWORK" &&
                program.enrollment && program.enrollment !== "active" && (
                  <Button
                    text={
                      program.enrollment === "not-enrolled"
                        ? "Apply"
                        : "Requested"
                    }
                    icon={
                      program.enrollment === "not-enrolled" ? "tick" : "time"
                    }
                    variant="primary"
                    disabled={program.enrollment !== "not-enrolled"}
                    onClick={() => setIsApplicationOpenforApply(true)}
                  />
                )
              }

              {program.role === "mentee" &&
                program.programType !== "1_1_NETWORK" && (
                  // the button only show to mentee not mentor
                  <Button
                    text={"Request a Meeting"}
                    // icon={program.enrollment === "not-enrolled" ?  "tick" : "time"}
                    variant="primary"
                    icon={"calendar"}
                    // disabled={program.enrollment !== "not-enrolled"}
                    onClick={() => setIsApplicationOpen(true)}
                  />
                )}

              {program.role === "manager" && (
                <Button
                  text={"Supercharge"}
                  // icon={program.enrollment === "not-enrolled" ?  "tick" : "time"}
                  variant="primary"
                  // disabled={program.enrollment !== "not-enrolled"}
                  onClick={() => setIsSuperCharge(true)}
                  isSupercharge={true}
                />
              )}
              {program.role === "mentor_manager" && (
                <Button
                  text={"Supercharge"}
                  // icon={program.enrollment === "not-enrolled" ?  "tick" : "time"}
                  variant="primary"
                  // disabled={program.enrollment !== "not-enrolled"}
                  onClick={() => setIsSuperCharge(true)}
                  isSupercharge={true}
                />
              )}

              {isSuperCharge && (
                <Modal onClose={() => setIsSuperCharge(false)}>
                  <Payment
                    ismodalOpen={isSuperCharge}
                    setIsmodalOpen={setIsSuperCharge}
                    title={program?.title}
                    program={program}
                  />
                </Modal>
              )}
            </>
          }
        </ProgramInfo>

        
         
        
          <EditOptionsOuter>
            {showSurveyButton && <>
              {/* survey button */}
          <SurvyListButton  onClick={() => setOpenSurveyListModal(true)}  >
                  <Icon type="group" color="#44449B" />
                  Survey
           </SurvyListButton>
          {/* survey button */}</> }
            
          {editMode && (
            <>
            <EditDropdown onClick={() => setOpenEdit(!openEdit)}>
              <Icon type="edit" color="#333232" />
              Edit
              <Icon type="chevron-down" color="#333232" size="18" />
            </EditDropdown>
            {openEdit && (
              <EditModeOptions>
                <EditButton
                  onClick={() => {
                    setShowEditProgram(true);
                  }}
                >
                  <Icon type="edit" color="#333232" />
                  Edit Program
                </EditButton>
                <EditButton
                  onClick={() => {
                    setShowEditProgramManagers(true);
                  }}
                >
                  <Icon type="edit" color="#333232" />
                  Edit Program Managers
                </EditButton>
                <EditButton
                  onClick={() => {
                    setShowApplicationEditor(true);
                  }}
                >
                  <Icon type="edit" color="#333232" />
                  Edit Program Application
                </EditButton>
                <EditButton
                  onClick={() => {
                    setIsAddParticipantsOpen(true);
                  }}
                >
                  <Icon type="add" color="#333232" />
                  Add Participants
                </EditButton>

                {/* {features.announcements && (
                <>
                  <Button
                    icon="send"
                    text="Send Announcement"
                    variant={"primary"}
                    onClick={() => {
                      setShowSendAnnouncement(true);
                    }}
                  />
                  <ButtonSpacer />
                </>
              )} */}

                <EditButton
                  onClick={() => {
                    setIsModalOpen(true);
                  }}
                >
                  <Icon
                    type={isDeletingProgram ? "loading" : "trash"}
                    color="#333232"
                  />
                  Delete Program
                </EditButton>

                {isModalOpen && (
                  <Modal
                    title={"Are you sure you want to proceed?"}
                    isDelete={true}
                    onClose={
                      closeModal
                      // setShowEditProgramManagers(false)
                    }
                  >
                    <SectionActions>
                      <Button
                        icon={isDeletingProgram ? "loading" : "trash"}
                        variant="danger"
                        onClick={deleteProgram}
                        text="Delete Program"
                      />
                      <ButtonSpacer />
                      <Button
                        icon="tick"
                        variant="primary"
                        onClick={() => setIsModalOpen(false)}
                        text="Cancel"
                      />
                    </SectionActions>
                  </Modal>
                )}
              </EditModeOptions>
            )}
            </>
          )}</EditOptionsOuter>
       
      </ProgramHomeHeader>

      <AboutContent>
        <AboutTitle>About</AboutTitle>
        <AboutDescription>
          <div
            dangerouslySetInnerHTML={{
              __html: convertToLinks(formatsDescription(program.description)),
            }}
          />
        </AboutDescription>
        {features.announcements &&
          program.enrollment &&
          program.enrollment === "active" && (
            <AnnouncementsContent>
              <PublishAnnouncement>
                <AnnouncementsTitle>Announcements</AnnouncementsTitle>
                <AddAnnouncement
                  onClick={() => {
                    setShowSendAnnouncement(true);
                  }}
                >
                  <Icon type="add" color="#FFFFFF" />
                </AddAnnouncement>
              </PublishAnnouncement>
              <Announcements
                editMode={editMode}
                refreshProgram={refreshProgram}
              />
            </AnnouncementsContent>
          )}
      </AboutContent>
      {showEditProgram && (
        <>
          <EditProgram
            programId={params.selectedProgram}
            setShowAddEditProgram={(shouldRefresh) => {
              shouldRefresh && refreshProgram();
              setShowEditProgram(false);
            }}
          />
        </>
      )}

      {showEditProgramManagers && (
        <EditProgramManagers
          editProgram={params.selectedProgram}
          setShowEditProgramManagers={(shouldRefresh) => {
            shouldRefresh && refreshProgram();
            setShowEditProgramManagers(false);
          }}
        />
      )}
      {showSendAnnouncement && (
        <SendAnnouncement
          announcementProgram={params.selectedProgram}
          closeModal={(refresh) => {
            if (refresh) {
              refreshProgram();
            }
            setShowSendAnnouncement(false);
          }}
        />
      )}
      {showApplicationEditor && (
        <ApplicationEditorModal
          programId={params.selectedProgram}
          setShowApplicationEditor={setShowApplicationEditor}
        />
      )}
      {isAddParticipantsOpen && (
        <AddParticipants
          program={program}
          closeModal={() => {
            setIsAddParticipantsOpen(false);
          }}
        />
      )}
      {isApplicationOpenforApply &&
        //  && program.programType==='1_1_NETWORK'
        program.enrollment &&
        program.enrollment !== "active" && (
          <ApplicationModal
            programName={program}
            closeModal={() => {
              setIsApplicationOpenforApply(false);
            }}
          />
        )}
      {isApplicationOpen && (
        <Modal onClose={() => setIsApplicationOpen(false)}>
          <NewRequestForm setShowForm={setIsApplicationOpen} />
        </Modal>
      )}

    {/* Survey Modal */} 
    {openSurveyListModal &&  <SurveyListModal {...{programId,setOpenSurveyListModal}} />}
     
    </ProgramHomeContainer>
  );
}
export default ProgramHome;
