import React, { useState } from "react";
import { useParams } from "react-router-dom";
import callAPI from "../../../../../../api/apiCaller.js";
import Icon from "../../../../../../common/Icon";
import TextInput from "../../../../../../common/TextInput/index.js";
import { ConvertDateToString } from "../../../../../../common/utils.js";
import { Context } from "../../../../../../Store/index.js";
import Question from "../components/Question.js";
import {
  CloseButton,
  ModalButton,
  ModalCloseHeader,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalTitle,
  SurveyButton,
  SurveyFormContainer,
  SurveyFormContent,
  SurveyInput,
  SurveyToggleButton,
  SurveyToggleButtons,
  SurveyToggleContainer,
} from "../styles.js";

const SurveyFormModal = ({
  surveyForm = null,
  showSurveyForm,
  getAllSurveys,
  setShowSurveyForm,
  assignedTo,
  setAssignedTo,
}) => {
  let params = useParams();
  const programId = +params?.selectedProgram;
  const { dispatch } = React.useContext(Context);
  const [fillAsAnonymous, setFillAsAnonymous] = useState(
    surveyForm?.fillAsAnonymous || false
  );
  const [name, setName] = useState(surveyForm?.name || "");
  const [questions, setQuestions] = useState(surveyForm?.questions || []);
  const [endedOn, setEndedOn] = useState(
    surveyForm?.endedOn ? ConvertDateToString(surveyForm?.endedOn) : null
  );
  const [loading, setLoading] = useState(false);

  // const [questions, setQuestions] = useState(surveyFormData.questions||[]);
  

  const setDefaultValues = () => {
    setFillAsAnonymous(false);
    setName("");
    setQuestions([]);
    setAssignedTo("mentor");
  };

  const handleAddQuestion = () => {
    const newQuestion = {
      type: "checkbox",
      question: "",
      answers: [],
    };
    setQuestions([...questions, newQuestion]);
  };

  const createSurvey = (payload) => {
    setLoading(true);
    callAPI(dispatch, "createSurvey", payload).then((res) => {
      setDefaultValues();
      getAllSurveys();
      setShowSurveyForm(false);
      setLoading(false);
    });
  };
  const updateSurvey = (payload) => {
    setLoading(true);
    
    callAPI(dispatch, "updateSurvey", { id: surveyForm?.id, ...payload }).then(
      (res) => {
        setDefaultValues();
        getAllSurveys();
        setShowSurveyForm(false);
        setLoading(false);
      }
    );
  };

  const handleSaveSurveyForm = () => {
    const payload = {
      fillAsAnonymous,
      name,
      assignedTo,
      programId,
      questions,
      endedOn,
    };
    
    if (!surveyForm) {
      createSurvey(payload);
    } else {
      updateSurvey(payload);
    }
  };

  const handleCloseSurveyForm = () => {
    setDefaultValues();
    setShowSurveyForm(false);
  };

  return (
    <>
      <ModalOverlay onClick={() => setShowSurveyForm(null)}>
        <SurveyFormContainer onClick={(e) => e.stopPropagation()}>
          {/* header */}
          <ModalHeader>
            <ModalTitle>
              {`${surveyForm ? "Update" : "Create"} Survey Form`}
            </ModalTitle>
            <ModalCloseHeader>
              <CloseButton onClick={handleCloseSurveyForm}>
                <span>
                  <Icon type="close"></Icon>
                </span>
              </CloseButton>
            </ModalCloseHeader>
          </ModalHeader>

          {/* content */}
          <SurveyFormContent>
            <span>Survey Name</span>
            <SurveyInput
              placeholder="Type survey name here"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <SurveyToggleContainer>
              <p>Fill out the survey as Anonymous?</p>
              <SurveyToggleButtons>
                <SurveyToggleButton
                  isActive={fillAsAnonymous}
                  onClick={() => setFillAsAnonymous(true)}
                >
                  <span>Yes</span>
                </SurveyToggleButton>
                <SurveyToggleButton
                  isActive={!fillAsAnonymous}
                  onClick={() => setFillAsAnonymous(false)}
                >
                  <span>No</span>
                </SurveyToggleButton>
              </SurveyToggleButtons>
            </SurveyToggleContainer>
            <SurveyToggleContainer>
              <p>Survey will be ended on</p>
              <p>
                <TextInput
                  value={endedOn}
                  type="date"
                  // isValid={isAddFormValid["date"]}
                  // validator={validateDate}
                  // setIsValid={updateValid(isAddFormValid,setIsAddFormValid,["date"])}
                  setValue={(newDate) => {
                    setEndedOn(newDate);
                    
                  }}
                />
              </p>
            </SurveyToggleContainer>
            {/* Questions */}
            {questions.length ? (
              questions.map((question, index) => (
                <Question
                  index={index}
                  questions={questions}
                  setQuestions={setQuestions}
                  key={question.id}
                  question={question}
                />
              ))
            ) : (
              <div style={{ textAlign: "center", color: "grey", margin: "30px 0" }}  >
                No questions added yet
              </div>
            )}
            <SurveyButton variant="addNew" onClick={handleAddQuestion}>
              + Add New Question
            </SurveyButton>
            <ModalFooter>
              <ModalButton variant="cancel" onClick={handleCloseSurveyForm}>
                Cancel
              </ModalButton>
              <ModalButton onClick={handleSaveSurveyForm} disabled={loading}>
                {loading && <Icon type={"loading"} color="#FFF" />}Save
              </ModalButton>
            </ModalFooter>
          </SurveyFormContent>
        </SurveyFormContainer>
      </ModalOverlay>
    </>
  );
};

export default SurveyFormModal;
