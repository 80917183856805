import React from "react";
import Icon from "../../../../../../common/Icon";
import {
  CloseButton,
  Footer,
  ModalButton,
  ModalCloseHeader,
  ModalContent,
  ModalHeader,
  ModalOption,
  ModalOptionTitle,
  ModalOverlay,
  ModalTitle,
  OptionBox,
  OptionsHeading,
  OptionsWrapper,
} from "../styles.js";

const AssignToUserModal = ({
  handleNext,
  assignedTo,
  setAssignedTo,
  assignToUserModal,
  setAssignToUserModal,
}) => {
  const handleCloseModal = () => setAssignToUserModal(false);
  return (
    <ModalOverlay onClick={handleCloseModal}>
      <ModalContent onClick={(e) => e.stopPropagation()}>
        {/* header */}
        <ModalHeader>
          <ModalTitle>Create a new Survey</ModalTitle>
          <ModalCloseHeader>
            <CloseButton onClick={handleCloseModal}>
              <span>
                <Icon type="close"></Icon>
              </span>
            </CloseButton>
          </ModalCloseHeader>
        </ModalHeader>
        {/* content */}
        <OptionsHeading>
          <span>Assign to</span>
        </OptionsHeading>
        <OptionsWrapper>
          <ModalOption>
            <OptionBox
              selected={assignedTo === "mentor"}
              onClick={() => setAssignedTo("mentor")}
            >
              <Icon type="mentor" />
            </OptionBox>
            <ModalOptionTitle>
              <span>Mentors</span>
            </ModalOptionTitle>
          </ModalOption>
          <ModalOption>
            <OptionBox
              selected={assignedTo === "mentee"}
              onClick={() => setAssignedTo("mentee")}
            >
              <Icon type="mentees" />
            </OptionBox>
            <ModalOptionTitle>
              <span>Mentee</span>
            </ModalOptionTitle>
          </ModalOption>
        </OptionsWrapper>
        <Footer>
          <ModalButton variant="cancel" onClick={handleCloseModal}>
            Cancel
          </ModalButton>
          <ModalButton onClick={handleNext}>Next</ModalButton>
        </Footer>
      </ModalContent>
    </ModalOverlay>
  );
};

export default AssignToUserModal;
