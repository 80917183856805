import React from "react";
import { Cell, Pie, PieChart, ResponsiveContainer } from "recharts";
import {
  ColorDot,
  CustomLegendContainer,
  CustomLegendInner,
  CustomLegendValue,
  MatchChartOuter,
  NoResponseText,
  ResponseTextDiv,
} from "./styles";

const PieChartComponent = ({ question }) => {
  //   const question = {
  //     aggregate: {
  //         "2": 0,
  //         "3": 1,
  //         "11": 0
  //     },
  //     question: "Type Your Question Here ...!1"
  // }
  // const data = [
  //   { name: "Pieed", value: 83 },
  //   { name: "Not yet matched", value: 32 },
  //   { name: "Not yet matched", value: 12 },
  //   { name: "Not yet matched", value: 22 },
  // ];
  const data = Object.keys(question.aggregate).map((key) => ({
    name: key,
    value: question.aggregate[key] || 0,
  }));
  const totalAnswers = Object.keys(question.aggregate).reduce(
    (m, c) => m + question.aggregate[c],
    0
  );
  const CustomLegend = () => (
    <CustomLegendContainer>
      {data.map((entry, index) => (
        <CustomLegendInner key={`legend-item-${index}`}>
          <ColorDot
            style={{
              backgroundColor: COLORS[index],
            }}
          ></ColorDot>
          <CustomLegendValue>{entry.name}</CustomLegendValue>
        </CustomLegendInner>
      ))}
    </CustomLegendContainer>
  );
  console.log(totalAnswers, "data ==>", data);
  return (
    <MatchChartOuter>
      <ResponseTextDiv>
        <span>{totalAnswers} responses</span>
      </ResponseTextDiv>
      {totalAnswers < 1 ? (
        <NoResponseText>No response submitted yet</NoResponseText>
      ) : (
        <>
          <ResponsiveContainer width={230} height={250}>
            <PieChart width={300} height={300}>
              <Pie
                data={data}
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="50%"
                innerRadius={70}
                outerRadius={110}
                fill="#8884d8"
                paddingAngle={0}
                labelLine={false}
                label={({
                  cx,
                  cy,
                  midAngle,
                  innerRadius,
                  outerRadius,
                  percent,
                  value,
                  index,
                }) => {
                  const RADIAN = Math.PI / 180;
                  const radius =
                    innerRadius + (outerRadius - innerRadius) * 0.5;
                  const x = cx + radius * Math.cos(-midAngle * RADIAN);
                  const y = cy + radius * Math.sin(-midAngle * RADIAN);
                  const rectSize = 28;
                  const labelOffset = rectSize / 2;
                  return (
                    <g>
                      <rect
                        x={x - labelOffset}
                        y={y - labelOffset}
                        width={rectSize}
                        height={rectSize}
                        fill="white"
                        rx={14}
                        ry={14}
                        stroke="#ccc"
                      />
                      <text
                        x={x}
                        y={y}
                        fill="#302F2F"
                        textAnchor="middle"
                        dominantBaseline="middle"
                        fontSize={14}
                        fontWeight="500"
                        fontFamily="Nunito Sans"
                      >
                        {value}
                      </text>
                    </g>
                  );
                }}
              >
                {data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index]} />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
          <CustomLegend />
        </>
      )}
    </MatchChartOuter>
  );
};

export default PieChartComponent;

const COLORS = [
  "#4D4D9F", // Purple Blue
  "#386CFA", // Blue
  "#51D1FF", // Light Blue
  "#F7A61B", // Yellow Orange
  "#AE681C", // Orange
  "#D12C3C", // Red
  "#1E8D8D", // Teal
  "#F27D8D", // Light Pink
  "#3A9F76", // Green
  "#F4B7C4", // Light Pinkish
  "#FF6347", // Tomato
  "#32CD32", // Lime Green
  "#FFD700", // Gold
  "#800080", // Purple
  "#008080", // Teal
  "#A52A2A", // Brown
  "#800000", // Maroon
  "#00CED1", // Dark Turquoise
  "#FF1493", // Deep Pink
  "#00FF00", // Lime
  "#B22222", // Firebrick
  "#483D8B", // Dark Slate Blue
  "#556B2F", // Dark Olive Green
];
