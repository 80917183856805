import React from "react";
import Icon from "../../../../../../common/Icon";
import BarChartComponent from "../components/charts/BarChartComponent.js";
import PieChartComponent from "../components/charts/PieChartComponent.js";
import {
  CloseButton,
  CompletedSurveyContainer,
  ModalButton,
  ModalCloseHeader,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ProductBoxDetail,
  ProductBoxEmails,
  ProductBoxInner,
  ProductBoxTitle,
  ProductExperienceBox,
} from "../styles.js";
import SurveyLoader from "../components/SurveysLoader.js";

const UserSurveyAggregateModal = ({
  userSurveyAggregate,
  setUserSurveyAggregate,
  setSurveyAggregateLoading,
}) => {
  const handleCloseModal = () => {
    setUserSurveyAggregate(null);
    setSurveyAggregateLoading(false);
  };

  
  return (
    <CompletedSurveyContainer>
      {/* header */}

      <ModalHeader>
        <ModalTitle>Product Experience Aggregate report</ModalTitle>
        <ModalCloseHeader>
          <CloseButton onClick={handleCloseModal}>
            <span>
              <Icon type="close"></Icon>
            </span>
          </CloseButton>
        </ModalCloseHeader>
      </ModalHeader>
      {/* Charts List  */}
      {userSurveyAggregate ? (
        userSurveyAggregate?.questions?.map((question, i) => (
          <>
            <ProductExperienceBox>
              <ProductBoxDetail>
                <ProductBoxTitle>{question?.question}</ProductBoxTitle>

                {/* <ProductDownloadButton>
                <Icon type="downloadIcon" />
                Download
              </ProductDownloadButton> */}
              </ProductBoxDetail>
              <ProductBoxInner>
                <ProductBoxEmails>
                  {i % 2 === 0 ? (
                    <PieChartComponent {...{ question }} />
                  ) : (
                    <BarChartComponent {...{ question }} />
                  )}
                </ProductBoxEmails>
              </ProductBoxInner>
            </ProductExperienceBox>
          </>
        ))
      ) : (
        <SurveyLoader text={"Survey Aggregate"} />
      )}

      {/* Emails List  */}
      <ProductExperienceBox>
        <ProductBoxDetail>
          <ProductBoxTitle>
            Which email address do you actively use the most{" "}
          </ProductBoxTitle>

          {/* <ProductDownloadButton>
            <Icon type="downloadIcon" />
            Download
          </ProductDownloadButton> */}
        </ProductBoxDetail>
        <ProductBoxInner>
          <ProductBoxEmails>
            <span>{userSurveyAggregate?.emails?.length} responses</span>
            <ul>
              {userSurveyAggregate?.emails?.map((email, i) => (
                <li key={i}>{email}</li>
              ))}
            </ul>
          </ProductBoxEmails>
        </ProductBoxInner>
      </ProductExperienceBox>

      <ModalFooter>
        <ModalButton variant="cancel" onClick={handleCloseModal}>
          Close
        </ModalButton>
      </ModalFooter>
    </CompletedSurveyContainer>
  );
};

export default UserSurveyAggregateModal;
