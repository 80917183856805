import React, { useState } from "react";
import Icon from "../../../../../../common/Icon";
import CustomDropdown from "./QuestionSelect.js";
import {
  AddAnswer,
  AnswerText,
  DeleteButton,
  OptionContainer,
  QuestionCol,
  QuestionContainer,
  QuestionHeader,
  QuestionText,
} from "../styles.js";

export const answerDropdown = [
  { value: "free-response", text: "Free Response", icon: "freeresponse" },
  { value: "radio-button", text: "Multiple Choice", icon: "multichoice" },
  { value: "checkbox", text: "Checkboxes", icon: "checkboxes" },
  { value: "dropdown", text: "Dropdown", icon: "dropdown" },
];

const Question = ({ question, setQuestions, index, questions }) => {
  const dropdown = answerDropdown.find((a) => a.value === question?.type);
  
  const [selectedOption, setSelectedOption] = useState(dropdown);

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    setQuestions((prev) =>
      prev.map((q, i) => (i === index ? { ...q, type: option.value } : q))
    );
    
  };

  const handleAddNewAnswer = () => {
    setQuestions((prev) =>
      prev.map((q, i) =>
        i === index ? { ...q, answers: [...q.answers, { answer: "" }] } : q
      )
    );
  };

  const handleDeleteQuestion = (questionIndex) => {
    
    setQuestions((prev) => prev.filter((q, i) => i !== questionIndex));
  };

  const handleDeleteAnswer = (questionIndex, answerIndex) => {
    setQuestions((prev) =>
      prev.map((question, qIndex) =>
        qIndex === questionIndex
          ? {
              ...question,
              answers: question.answers.filter(
                (_, aIndex) => aIndex !== answerIndex
              ),
            }
          : question
      )
    );
  };

  const handleQuestionChange = (index, value) => {
    setQuestions((prev) => {
      const updatedQuestions = [...prev]; // Create a shallow copy to avoid mutation

      if (updatedQuestions[index].question !== value) {
        updatedQuestions[index] = {
          ...updatedQuestions[index],
          question: value,
        }; // Update only the relevant question
      }

      return updatedQuestions;
    });
  };

  const handleAnswerChange = (questionIndex, answerIndex, value) => {
    
    setQuestions((prev) =>
      prev.map((question, qIndex) =>
        qIndex === questionIndex
          ? {
              ...question,
              answers: question.answers.map((answer, aIndex) =>
                aIndex === answerIndex ? { ...answer, answer: value } : answer
              ),
            }
          : question
      )
    );
  };

  
  return (
    <QuestionContainer>
      <QuestionHeader>
        <QuestionCol>
          <span>
            Question {index + 1}{" "}
            <span onClick={() => handleDeleteQuestion(index)}>
              <Icon type="close"></Icon>
            </span>
          </span>
          <QuestionText
            placeholder="Type your question here"
            value={question.question}
            onChange={(e) => handleQuestionChange(index, e.target.value)}
          />
        </QuestionCol>
        <CustomDropdown
          options={answerDropdown}
          selectedOption={selectedOption}
          onSelect={handleOptionSelect}
        />
      </QuestionHeader>
      {question?.type !== "free-response" &&
        question.answers.map((answer, answerIndex) => (
          <OptionContainer key={answerIndex}>
            {answerIndex + 1}.
            <AnswerText
              placeholder="Type your answer here"
              value={answer.answer}
              onChange={(e) =>
                handleAnswerChange(index, answerIndex, e.target.value)
              }
            />
            <DeleteButton
              onClick={() => handleDeleteAnswer(index, answerIndex)}
            >
              {" "}
              <Icon type={"delete"} color="#ff2d00" />
            </DeleteButton>
            {/* <Icon type={"delete"} color="#302F2F" /> */}
          </OptionContainer>
        ))}

      {question?.type !== "free-response" && (
        <AddAnswer onClick={handleAddNewAnswer}>Add Answer</AddAnswer>
      )}
    </QuestionContainer>
  );
};

export default Question;
