import Icon from "../../../../../../common/Icon";
import {
  CloseButton,
  CompletedSurveyContainer,
  ModalCloseHeader,
  ModalHeader,
  ModalOverlay,
  ModalTitle,
  OnemauveFooter,
  ProfileInfo,
  UserSurveyFormButton,
  UserSurveyFormDate,
  UserSurveyFormHeader,
  UserSurveyFormLabel,
  UserSurveyFormOption,
  UserSurveyFormOptionGroup,
  UserSurveyFormProfile,
  UserSurveyFormProfileInfo,
  UserSurveyFormProfileThumb,
  UserSurveyFormProfileTitle,
  UserSurveyFormSection,
} from "../styles.js";

const UserSurveyAnswersModal = ({
  userSubmittedSurvey,
  userAnswer,
  setUserSubmittedSurvey,
  userFreeResponseAnswers,
  setCompletedSurveyLoading,
}) => {
  
  const user = userSubmittedSurvey?.user || {};
  const thumb = user?.image;
  const name = `${user?.firstName || ""} ${user?.lastName || ""}`;
  const completedDate = userSubmittedSurvey?.createdAt;
  const email = user?.email;
  const university = user?.university || ""; // Placeholder for university
  const roles = user?.roles?.join(", ");

  const showFormattedDate = (date) => {
    return new Date(date).toLocaleString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true, // 12-hour format
      month: "numeric",
      day: "numeric",
      year: "numeric",
    });
  };

  return (
    <ModalOverlay
      onClick={(e) => {
        e.stopPropagation()
        setUserSubmittedSurvey(null);
        // setCompletedSurveyLoading(false);
      }}
    >
      <CompletedSurveyContainer onClick={(e) => e.stopPropagation()}>
        {/* Modal Header */}
        <ModalHeader>
          <ModalTitle>Onemauve Review </ModalTitle>
          <ModalCloseHeader>
            <CloseButton
              onClick={() => {
                setUserSubmittedSurvey(null);
                setCompletedSurveyLoading(false);
              }}
            >
              <Icon type="close" />
            </CloseButton>
          </ModalCloseHeader>
        </ModalHeader>

        {/* User Info section */}
        <UserSurveyFormHeader>
           <UserSurveyFormProfile>
           {!userSubmittedSurvey?.user ? <>Anonymous User</> :
            <UserSurveyFormProfileInfo>
              <UserSurveyFormProfileThumb>
                <img src={thumb} alt={name} width={50} height={50} />
              </UserSurveyFormProfileThumb>
              <UserSurveyFormProfileTitle>
                <h3>{name}</h3>
                <p>{roles}</p>
              </UserSurveyFormProfileTitle>
            </UserSurveyFormProfileInfo>
}
            <UserSurveyFormDate>
              <small>Survey Completed on</small>
              <small>{showFormattedDate(completedDate)}</small>
            </UserSurveyFormDate>
            {!userSubmittedSurvey?.user ? <> </> :<> 
            <ProfileInfo>
              <span>Email</span>
              <a href={`mailto:${email}`}>{email}</a>
            </ProfileInfo>
            {university && (
              <ProfileInfo>
                <span>University Attended</span>
                <span>{university}</span>
              </ProfileInfo>
              
            )}
            </>}
          </UserSurveyFormProfile> 
         
        </UserSurveyFormHeader>

        {/* User Survey Answers */}
        {userSubmittedSurvey?.survey?.questions?.map((question) => (
          <UserSurveyFormSection key={question.id}>
            <UserSurveyFormLabel>{question?.question}</UserSurveyFormLabel>
            {question.type === "free-response" ? (
              <>
                {userFreeResponseAnswers &&
                  userFreeResponseAnswers[question?.id]}
              </>
            ) : (
              <UserSurveyFormOptionGroup>
                {question?.answers?.map((answer) => (
                  <UserSurveyFormOption key={answer.id}>
                    <input
                      disabled={true}
                      type="checkbox"
                      // onClick={(e) => handleUserAnswer(e, question.id, answer.id)}
                      checked={userAnswer[answer.id] ? true : false}
                    />
                    <span>{answer?.answer}</span>
                  </UserSurveyFormOption>
                ))}
              </UserSurveyFormOptionGroup>
            )}
          </UserSurveyFormSection>
        ))}
        {/*Modal Footer */}
        <OnemauveFooter>
          <UserSurveyFormButton
            variant="close"
            onClick={(e) => {
              e.stopPropagation()
              setUserSubmittedSurvey(null);
              // setCompletedSurveyLoading(false);
            }}
          >
            Close
          </UserSurveyFormButton>
        </OnemauveFooter>
      </CompletedSurveyContainer>
    </ModalOverlay>
  );
};

export default UserSurveyAnswersModal;
