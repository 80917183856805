import React from "react";
import Icon from "../../../../../../common/Icon";
import SurveyLoader from "../components/SurveysLoader.js";
import {
  CloseButton,
  CompletedSurveyActionButton,
  CompletedSurveyCloseButton,
  CompletedSurveyContainer,
  CompletedSurveyContent,
  CompletedSurveyRoleBadge,
  CompletedSurveyTable,
  CompletedSurveyTableCell,
  CompletedSurveyTableHeader,
  CompletedSurveyTableRow,
  ModalCloseHeader,
  ModalHeader,
  ModalOverlay,
  ModalTitle,
  SurveyTableOuter,
} from "../styles.js";

const CompletedSurveyListModal = ({
  completedSurveys,
  setCompletedSurveys,
  handleViewSurveyResponse,
  setCompletedSurveyLoading,
}) => {
  const handleCloseModal = () => {
    setCompletedSurveyLoading(false);
    setCompletedSurveys(null);
  };

  const showFormattedDate = (date) => {
    return new Date(date).toLocaleString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true, // You can set this to false for 24-hour time format
    });
  };

  
  return (
    <ModalOverlay onClick={handleCloseModal}>
      <CompletedSurveyContainer onClick={(e) => e.stopPropagation()}>
        {/* header */}
        <ModalHeader>
          <ModalTitle>Completed Survey</ModalTitle>
          <ModalCloseHeader>
            <CloseButton onClick={handleCloseModal}>
              <span>
                <Icon type="close"></Icon>
              </span>
            </CloseButton>
          </ModalCloseHeader>
        </ModalHeader>
        {/* main content  */}
        <CompletedSurveyContent>
          {/* search container */}
          {/* <SearchContainer>
          <SearchInput type="text" placeholder="Search user by name" />
          <Icon type="search-icon"></Icon>
        </SearchContainer> */}
          {/* Table  */}
          <SurveyTableOuter>
            {completedSurveys ? (
              <CompletedSurveyTable>
                <thead>
                  <tr>
                    <CompletedSurveyTableHeader>
                      Full Name
                    </CompletedSurveyTableHeader>
                    <CompletedSurveyTableHeader>
                      Role
                    </CompletedSurveyTableHeader>
                    <CompletedSurveyTableHeader>
                      Date Completed
                    </CompletedSurveyTableHeader>
                    <CompletedSurveyTableHeader>
                      Action
                    </CompletedSurveyTableHeader>
                  </tr>
                </thead>
                <tbody>
                  {completedSurveys.map((item) => (
                    <CompletedSurveyTableRow key={item.id}>
                      <CompletedSurveyTableCell>
                        <img
                          style={{ height: "20px" }}
                          src={item?.user?.image}
                          alt=""
                        />
                        <span>
                          {item?.user ? item?.user?.firstName + " " + item?.user?.lastName : "Anyonymous User"}
                        </span>
                      </CompletedSurveyTableCell>
                      <CompletedSurveyTableCell>
                        <CompletedSurveyRoleBadge role={item.role}>
                          {item?.user ? item?.user?.roles?.join(", "): "N/A"}
                        </CompletedSurveyRoleBadge>
                      </CompletedSurveyTableCell>
                      <CompletedSurveyTableCell>
                        {showFormattedDate(item.createdAt)}
                      </CompletedSurveyTableCell>
                      <CompletedSurveyTableCell>
                        <Icon type="comment"></Icon>
                        <CompletedSurveyActionButton
                          onClick={() => handleViewSurveyResponse(item)}
                        >
                          View More
                        </CompletedSurveyActionButton>
                      </CompletedSurveyTableCell>
                    </CompletedSurveyTableRow>
                  ))}
                </tbody>
              </CompletedSurveyTable>
            ) : (
              <SurveyLoader text={"Completed Surveys"} />
            )}
          </SurveyTableOuter>
          {/* footer */}
          <CompletedSurveyCloseButton onClick={handleCloseModal}>
            Close
          </CompletedSurveyCloseButton>
        </CompletedSurveyContent>
      </CompletedSurveyContainer>
    </ModalOverlay>
  );
};

export default CompletedSurveyListModal;
