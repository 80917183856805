import React from "react";
import Loading from "../../../../../../common/Loading/index.js";
import { LoaderOuter } from "../styles.js";

const SurveyLoader = ({ text }) => {
  return (
    <LoaderOuter>
      <Loading loadingItem={text} />
    </LoaderOuter>
  );
};

export default SurveyLoader;
