import styled from "styled-components";
import { Scrollable, panelSpacing } from "../../../../../common/styles";
import colors from "../../../../../config/colors";

export const ProgramHomeContainer = styled.div`
  ${Scrollable}
  height:calc(100vh - 110px - ${panelSpacing * 2}px);
  margin: 16px 12px;
  @media (max-width: 767px) {
    height: calc(100vh - 170px - ${panelSpacing * 2}px);
  }
`;

export const ProgramHomeHeader = styled.div`
  padding: ${panelSpacing}px;
  display: flex;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 3.143px 31.429px 0px rgba(182, 174, 174, 0.25);
  @media (max-width: 640px) {
    flex-direction: column;
    gap: 20px;
  }
`;
export const ProgramImage = styled.img`
  width: 120px;
  height: 120px;
  margin-right: 20px;
  object-fit: cover;
  border-radius: 10px;
  border: 1px solid ${colors.backgroundGrey};
  @media (max-width: 1200px) {
    width: 160px;
    height: 160px;
  }
`;
export const ProgramInfo = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
`;
export const ProgramName = styled.div`
  color: #302f2f;
  font-family: "Nunito Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  @media (max-width: 1200px) {
    font-size: 24px;
  }
`;
export const ProgramOrganization = styled.div`
  color: #302f2f;
  font-family: "Nunito Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;
export const ProgramManagers = styled.div`
  margin: 8px 0px 6px;
`;
export const ProgramManagersLabel = styled.div`
  color: #302f2f;
  font-family: "Nunito Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 6px;
`;
export const ProgramManagersList = styled.div`
  display: flex;
`;

export const EditDropdown = styled.div`
  width: 110px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 5px;
  border: 1px solid #ededed;
  color: #333232;
  font-family: "Nunito Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-left: auto;
  cursor: pointer;
`;

export const ProgramManagerImage = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
  cursor: pointer;
  :hover {
    opacity: 0.7;
  }
  @media (max-width: 1200px) {
    width: 24px;
    height: 24px;
  }
`;
export const ProgramTrackedHours = styled.div`
  color: #302f2f;
  font-family: "Nunito Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const AboutContent = styled.div`
  padding: ${panelSpacing}px;
  border-radius: 10px;
  background: #fff;
  margin-top: 12px;
`;
export const AboutTitle = styled.div`
  margin-bottom: 6px;
  color: #302f2f;
  font-family: "Nunito Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;
export const AboutDescription = styled.div``;

export const AnnouncementsContent = styled.div``;
export const AnnouncementsTitle = styled(AboutTitle)`
  margin-top: 12px;
`;

export const AddAnnouncement = styled.div`
  border-radius: 5px;
  background: #4f449b;
  cursor: pointer;
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const PublishAnnouncement = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const EditModeOptions = styled.div`
  display: flex;
  border-radius: 5px;
  background: #fff;
  flex-direction: column;
  box-shadow: 0px 3.143px 31.429px 0px rgba(182, 174, 174, 0.25);
  position: absolute;
  left: -123px;
`;

export const LinkLine = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 1024px) {
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
  }
`;

export const Link = styled.a`
  color: ${colors.primary};
  margin-right: 15px;
  font-family: "Nunito Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  @media (max-width: 1200px) {
    font-size: 14px;
    line-break: anywhere;
  }
`;
export const PaymentContainer = styled.div`
  max-height: 500px;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 10px;
  }
`;
export const Paymentintro = styled.div``;
export const GuideOrderList = styled.ol`
  display: block;
`;
export const GuideList = styled.li`
  display: block;
  max-width: 950px;
`;
export const SunHeading = styled.h3`
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 0;
`;
export const ItemInfo = styled.div`
  margin-top: 10px;
`;
export const ButtonWraper = styled.div`
  width: ${(props) => props.width}%;
  margin: 10px 0;
  display: flex;
  flex-direction: ${(props) => (props.direction ? props.direction : "column")};
  justify-content: ${(props) => (props.gap ? props.gap : "")};
  gap: 10px;
`;
export const HorezentalLine = styled.hr`
  color: red;
`;
export const PaypalContaner = styled.div`
  max-height: 500px;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 10px;
  }
`;
export const PaymentNote = styled.span`
  display: block;
  font-weight: 700;
  margin-bottom: 10px;
`;
export const PaymentDotContainer = styled.div`
  display: flex;
  gap: 50px;
`;
export const Dot = styled.span`
  display: block;
  min-width: 20px;
  max-width: 20px;
  border-radius: 50px;
  border: 1px solid green;
  min-height: 20px;
  background: green;
`;
export const PaymentSuccess = styled.p`
  text-align: left;
  font-family: Inter;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0em;
  max-width: 55rem;
`;
export const PaymentSuccessHeading = styled.h2`
  text-align: left;
  font-family: Inter;
  font-size: 20px;
  font-weight: 800;
  line-height: 19px;
  letter-spacing: 0em;
  color: ${colors.primary};
  text-transform: capitalize;
`;
export const PremiumContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 45vh;
  background: ${colors.secondary};
  padding: 5px;
  gap: 5px;
`;
export const PersonalContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 10px;
  background: ${colors.background};
  border-radius: 10px;
  align-items: center;
  justify-content: center;
`;
export const TeamContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 10px;
  background: ${colors.background};
  border-radius: 10px;
  align-items: center;
  justify-content: center;
`;
export const PlatinumContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 10px;
  background: ${colors.background};
  border-radius: 10px;
  align-items: center;
  justify-content: center;
`;
export const Heading = styled.h1`
  text-align: center;
  text-transform: capitalize;
  font-size: 20px;
`;
export const ImagePreview = styled.img`
  width: 150px;
  border-radius: 10px;
  height: 150px;
`;
export const PremiumInfo = styled.div`
  display: flex;
  flex-direction: column;
`;
export const SubHeading = styled.h4`
  text-align: center;
  text-transform: capitalize;
  font-size: 16px;
`;

export const EditButton = styled.div`
  color: #333232;
  font-family: "Nunito Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 13px 12px;
  display: flex;
  gap: 16px;
  align-items: center;
  border-bottom: 1px solid #e2ebf3;
  cursor: pointer;
`;

export const EditOptionsOuter = styled.div`
  margin-left: auto;
  position: relative;
  display: flex;
`;

export const SurvyListButton = styled.button`
  border-radius: 5px;
  background: rgba(68, 68, 155, 0.1);
  display: inline-flex;
  height: 40px;
  padding: 5px 10px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  color: #44449b;
  font-family: "Nunito Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border:none;
  margin-right:10px;
  cursor: pointer;
`;
