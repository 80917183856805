import { useState } from "react";
import Icon from "../../../../../../common/Icon";
import TextInput from "../../../../../../common/TextInput";
import {
  CloseButton,
  CompletedSurveyContainer,
  DropDownIcon,
  DropDownOuter,
  DropSelect,
  ModalButton,
  ModalCloseHeader,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalTitle,
  MultipleChoiceInput,
  MultipleChoiceLabel,
  MultipleChoiceOuter,
  SurveyFormContent,
  SurveyToggleButton,
  SurveyToggleButtons,
  SurveyToggleContainer,
  UserSurveyFormHeader,
  UserSurveyFormLabel,
  UserSurveyFormSection,
} from "../styles.js";

const SubmitSurveyModal = ({
  submitSurveyModal,
  setSubmitSurveyModal,
  handleSubmitSurveyAnswer,
  submitSurveyLoading,
}) => {
  const [userAnswers, setUserAnswers] = useState({});
  const [freeResponseAnswers, setFreeResponseAnswers] = useState({});
 const [fillAsAnonymous, setFillAsAnonymous] = useState(false );
  const handleSubmitSurveyAnswers = async () => {
    const payload = { surveyId: submitSurveyModal.id,fillAsAnonymous, answers: [] };
    // answer , questionId
    const answersMapping = submitSurveyModal?.questions.reduce((m, q) => {
      const answers = q.answers?.reduce(
        (m, a) => ({ ...m, [a.id]: a.answer }),
        {}
      );
      return { ...m, ...answers };
    }, {});
    Object.keys(userAnswers).map((a) =>
      payload.answers.push({
        questionId: userAnswers[a],
        answer: answersMapping[a],
      })
    );
    Object.keys(freeResponseAnswers).map((a) =>
      payload.answers.push({ answer: freeResponseAnswers[a], questionId: +a })
    );
    await handleSubmitSurveyAnswer(payload);
  };
  
  return (
    <ModalOverlay>
      <CompletedSurveyContainer>
        {/* Modal Header */}
        <ModalHeader>
          <ModalTitle>Onemauve Review</ModalTitle>
          <ModalCloseHeader>
            <CloseButton onClick={() => setSubmitSurveyModal(null)}>
              <Icon type="close" />
            </CloseButton>
          </ModalCloseHeader>
        </ModalHeader>

        {/* User Info section */}
        <UserSurveyFormHeader></UserSurveyFormHeader>
            {
              submitSurveyModal?.fillAsAnonymous  &&  <SurveyFormContent>
              <SurveyToggleContainer>
                           <p>Fill out the survey as Anonymous?</p>
                           <SurveyToggleButtons>
                             <SurveyToggleButton
                               isActive={fillAsAnonymous}
                               onClick={() => setFillAsAnonymous(true)}
                             >
                               <span>Yes</span>
                             </SurveyToggleButton>
                             <SurveyToggleButton
                               isActive={!fillAsAnonymous}
                               onClick={() => setFillAsAnonymous(false)}
                             >
                               <span>No</span>
                             </SurveyToggleButton>
                           </SurveyToggleButtons>
                         </SurveyToggleContainer>
                         </SurveyFormContent>
            }
          
        {/* User Survey Answers */}
        {submitSurveyModal?.questions?.map((question) => (
          <UserSurveyFormSection key={question.id}>
            <UserSurveyFormLabel>{question?.question}</UserSurveyFormLabel>
            {/* free response  */}

            {/* checkbox  */}
            {question.type === "checkbox" && (
              <MultipleChoiceOuter>
                {question?.answers?.map((answer, i) => (
                  <MultipleChoiceLabel key={i}>
                    <MultipleChoiceInput
                      type="checkbox"
                      // name={`question${index}`}
                      value={userAnswers[answer?.id]}
                      onChange={(e) => {
                        

                        setUserAnswers((prev) => {
                          if (e.target.checked) {
                            prev[answer.id] = question.id;
                          } else {
                            delete prev[answer.id];
                          }
                          return prev;
                        });
                      }}
                    />
                    {answer?.answer}
                  </MultipleChoiceLabel>
                ))}
              </MultipleChoiceOuter>
            )}
            {/* checkbox  */}
            {question.type === "radio-button" && (
              <MultipleChoiceOuter>
                {question?.answers?.map((answer, i) => (
                  <MultipleChoiceLabel key={i}>
                    <MultipleChoiceInput
                      type="radio"
                      name={`question-${question.id}`}
                      // value={userAnswers[answer?.id]}
                      onClick={(e) => {
                        
                        const obj = Object.fromEntries(
                          Object.entries(userAnswers).filter(
                            ([key, value]) => value !== question.id
                          )
                        );
                        obj[answer.id] = question.id;
                        
                        setUserAnswers(obj);
                      }}
                    />
                    {answer?.answer}
                  </MultipleChoiceLabel>
                ))}
              </MultipleChoiceOuter>
            )}
            {/* dropdown  */}
            {question.type === "dropdown" && (
              <DropDownOuter>
                <DropSelect
                  place
                  //  value={qa.answer}
                  onChange={(e) => {
                    // 
                    const obj = Object.fromEntries(
                      Object.entries(userAnswers).filter(
                        ([key, value]) => value !== question.id
                      )
                    );
                    obj[e.target.value] = question.id;
                    
                    setUserAnswers(obj);
                  }}
                >
                  <option value="">Select an answer</option>
                  {question?.answers?.map((answer, i) => (
                    <option key={i} value={answer.id}>
                      {answer?.answer}
                    </option>
                  ))}
                </DropSelect>
                <DropDownIcon>
                  <Icon type={"chevron-down"} size="18" color="#302F2F" />
                </DropDownIcon>
              </DropDownOuter>
            )}
            {question.type === "free-response" && (
              <TextInput
                value={freeResponseAnswers[question?.id]}
                setValue={(newValue) => {
                  setFreeResponseAnswers((prev) => ({
                    ...prev,
                    [question?.id]: newValue,
                  }));
                }}
                placeholder="type here..."
              />
            )}
          </UserSurveyFormSection>
        ))}
        {/*Modal Footer */}

        <ModalFooter>
          <ModalButton
            variant="cancel"
            onClick={() => setSubmitSurveyModal(null)}
          >
            Close
          </ModalButton>
          <ModalButton
            disabled={submitSurveyLoading}
            onClick={() => handleSubmitSurveyAnswers()}
          >
            {submitSurveyLoading && <Icon type={"loading"} color="#FFF" />}
            Submit
          </ModalButton>
        </ModalFooter>
      </CompletedSurveyContainer>
    </ModalOverlay>
  );
};

export default SubmitSurveyModal;
