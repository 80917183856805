import React, { useState } from "react";
import Icon from "../../../../../../common/Icon";
import {
  DropdownContainer,
  DropdownButton,
  DropdownArrow,
  DropdownMenu,
  DropdownMenuItem,
} from "../styles.js";

const CustomDropdown = ({ options, selectedOption, onSelect }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleSelect = (option) => {
    onSelect(option);
    setIsOpen(false);
  };

  return (
    <DropdownContainer>
      <DropdownButton onClick={toggleDropdown}>
        {selectedOption?.icon && (
          <Icon
            type={selectedOption.icon}
            alt={`${selectedOption.text} icon`}
          />
        )}
        {selectedOption ? selectedOption.text : "Answer Option"}
        <DropdownArrow>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="20"
            viewBox="0 0 16 20"
            fill="none"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M13.0002 7.60017C13.0002 7.75378 12.9458 7.90738 12.8375 8.02438L8.39298 12.8245C8.17575 13.0592 7.82464 13.0592 7.60741 12.8245L3.16292 8.02438C2.94569 7.78978 2.94569 7.41056 3.16292 7.17596C3.38014 6.94135 3.73126 6.94135 3.94848 7.17596L8.00019 11.5519L12.0519 7.17596C12.2691 6.94135 12.6202 6.94135 12.8375 7.17596C12.9458 7.29296 13.0002 7.44656 13.0002 7.60017Z"
              fill="#333232"
            />
          </svg>
        </DropdownArrow>
      </DropdownButton>
      {isOpen && (
        <DropdownMenu>
          {options.map((option, index) => (
            <DropdownMenuItem key={index} onClick={() => handleSelect(option)}>
              <Icon type={option.icon} alt={`${option.text} icon`} />
              {option.text}
            </DropdownMenuItem>
          ))}
        </DropdownMenu>
      )}
    </DropdownContainer>
  );
};

export default CustomDropdown;
