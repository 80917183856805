import React, { useEffect, useState } from "react";
import callAPI from "../../../../../api/apiCaller.js";
import Icon from "../../../../../common/Icon";
import { Context } from "../../../../../Store";
import NoTask from "./assets/NoTasks.png";
import SurveysList from "./components/SurveysList.js";
import SurveysLoader from "./components/SurveysLoader.js";
import AssignToUserModal from "./modals/AssignToUserModal.js";
import CompletedSurveyListModal from "./modals/CompletedSurveyListModal.js";
import SubmitSurveyModal from "./modals/SubmitSurveyModal.js";
import SurveyFormModal from "./modals/SurveyFormModal.js";
import UserSurveyAggregateModal from "./modals/UserSurveyAggregateModal.js";
import UserSurveyAnswersModal from "./modals/UserSurveyAnswersModal.js";
import {
  AngleSpan,
  Button,
  CreateSurvey,
  CurrentPage,
  Image,
  MainTabsOuter,
  NextButton,
  NoSurveyButtons,
  NoSurveyContainer,
  NoSurveyDescription,
  NoSurveyImageWrapper,
  NoSurveyInner,
  NoSurveyTitle,
  PaginationButtons,
  PaginationSpan,
  PreviousButton,
  ResultsLabel,
  ResultsSelect,
  TablePagination,
} from "./styles.js";
import { useParams } from "react-router-dom";
// import { getUserInfo } from "../../../../Store/User/accessors";

const ALLOWED_SURVEY_CREATION_ROLES = ["manager","mentee_manager","mentor_manager"]

const Survey = () => {
  let params = useParams();
  const programId = +params?.selectedProgram;
  const { dispatch, state } = React.useContext(Context);
  const isUserProgramManger = state?.user?.userPrograms?.manager[programId] || false
  const isUserManger = state?.user?.roles?.some((role) =>ALLOWED_SURVEY_CREATION_ROLES.includes(role)) || false

  // const [isModalOpen, setModalOpen] = useState(false);
  const [assignToUserModal, setAssignToUserModal] = useState(null);
  const [showSurveyForm, setShowSurveyForm] = useState(false);
  const [userSubmittedSurvey, setUserSubmittedSurvey] = useState(false);
  const [userAnswer, setUserAnswer] = useState(null);
  const [userFreeResponseAnswers, setUserFreeResponseAnswers] = useState(null);
  const [surveyForm, setSurveyForm] = useState(null);
  const [submitSurveyLoading, setSubmitSurveyLoading] = useState(false);

  // completed Surveys
  const [completedSurveys, setCompletedSurveys] = useState(null);
  const [userSurveyAggregate, setUserSurveyAggregate] = useState(null);
  const [submitSurveyModal, setSubmitSurveyModal] = useState(null);
  const [completedSurveyLoading, setCompletedSurveyLoading] = useState(false);
  const [surveyAggregateLoading, setSurveyAggregateLoading] = useState(false);

  //  surveys List
  const [surveys, setSurveys] = useState(null);
  const [assignedTo, setAssignedTo] = useState("mentor");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [count, setCount] = useState(10);

  

  const handleCreateNewSurvey = () => {
    setAssignToUserModal(true);
    setSurveyForm(false);
  };

  const handleOpenSubmitSurveyModal = (survey) => {
    setSubmitSurveyModal(survey);
  };

  const handleEditSurvey = (survey) => {
    
    setSurveyForm(survey);
    setShowSurveyForm(true);
  };

  const handleDeleteSurvey = (survey) => {
    callAPI(dispatch, "deleteSurvey", { id: survey.id }).then((res) => {
      getAllSurveys();
    });
  };

  const handleNext = () => {
    setAssignToUserModal(false);
    setShowSurveyForm(true);
  };

  const getAllSurveys = () => {
    
    callAPI(dispatch, "getAllSurveys", { page, limit, programId }).then(
      (res) => {
        
        setSurveys(res?.data || []);
        setCount(res?.total || 0);
        // dispatch(setProgramList(programs.data))
      }
    );
  };

  const getAllSubmittedSurveys = (survey) => {
    setCompletedSurveyLoading(true);
    callAPI(dispatch, "getUserSurveysBySurveyId", { id: survey?.id }).then(
      (res) => {
        
        setCompletedSurveys(res?.data || []);
      }
    );
  };

  const getAggregateSurveyById = (survey) => {
    setSurveyAggregateLoading(true);
    callAPI(dispatch, "getAggregateSurveyById", { id: survey?.id }).then(
      (res) => {
        
        setUserSurveyAggregate(res?.data || []);
      }
    );
  };
  const handleSubmitSurveyAnswer = (payload) => {
    setSubmitSurveyLoading(true);
    callAPI(dispatch, "submitSurvey", payload).then((res) => {
      
      setSubmitSurveyLoading(false);
      setSubmitSurveyModal(false);
    });
  };

  const handleViewSurveyResponse = (survey) => {
    
    const answersList = survey?.survey?.questions.flatMap((q) =>
      q.answers?.map((a) => a)
    );

    const answers = survey.userSurveyAnswers.reduce((m, s) => {
      const answer = answersList.find(
        (a) => a.answer === s.answer && a.questionId === s.questionId
      );
      if (answer) {
        m[answer.id] = answer.questionId;
      }
      if (s.question.type === "free-response") {
        setUserFreeResponseAnswers((prev) => ({
          ...prev,
          [s.questionId]: s.answer,
        }));
      }
      return m;
    }, {});
    
    setUserSubmittedSurvey(survey);
    setUserAnswer(answers);
  };

  useEffect(() => {
    getAllSurveys();
  }, [limit, page]);

  
   return (
    <>
      {surveys === null ? (
        <SurveysLoader text={"Survey"} />
      ) : surveys?.length === 0 ? (
        <NoSurveyContainer>
          <NoSurveyInner>
            <NoSurveyImageWrapper>
              <Image src={NoTask} alt="No Task" />
            </NoSurveyImageWrapper>
            <NoSurveyTitle>
              You do not currently have any active surveys...
            </NoSurveyTitle>
            <NoSurveyDescription>
              Click the button below to create a new survey.
            </NoSurveyDescription>
            <NoSurveyButtons>
              <CreateSurvey onClick={handleCreateNewSurvey}>
                Create a Survey
              </CreateSurvey>
            </NoSurveyButtons>
          </NoSurveyInner>
        </NoSurveyContainer>
      ) : (
        <>
          {isUserProgramManger && isUserManger && (
            <Button onClick={handleCreateNewSurvey}>
              <Icon type="plus" />
              <span>Create New Survey</span>
            </Button>
          )}
          <MainTabsOuter>
            <SurveysList
              {...{
                handleEditSurvey,
                surveys,
                handleDeleteSurvey,
                getAllSubmittedSurveys,
                getAggregateSurveyById,
                handleOpenSubmitSurveyModal,
              }}
            />
            <TablePagination>
              <div>
                <ResultsLabel>
                  Results per page:
                  <ResultsSelect
                    value={limit}
                    onChange={(e) => setLimit(e.target.value)}
                  >
                    {[5, 10, 15, 20].map((size) => (
                      <option key={size} value={size}>
                        {size}
                      </option>
                    ))}
                  </ResultsSelect>
                </ResultsLabel>
              </div>
              <PaginationButtons>
                <PaginationSpan>
                  <CurrentPage>{page}</CurrentPage> out of{" "}
                  {Math.ceil(count / limit)}
                </PaginationSpan>
                <PreviousButton
                  onClick={() => page !== 1 && setPage(page - 1)}
                  disabled={page === 1}
                >
                  <AngleSpan>{"<"}</AngleSpan> Previous
                </PreviousButton>

                <NextButton
                  onClick={() =>
                    page !== Math.ceil(count / limit) && setPage(page + 1)
                  }
                  disabled={page === Math.ceil(count / limit)}
                >
                  Next <AngleSpan next={true}>{">"}</AngleSpan>
                </NextButton>
              </PaginationButtons>
            </TablePagination>
          </MainTabsOuter>
        </>
      )}
      {/* Modals */}
      {assignToUserModal && (
        <AssignToUserModal
          {...{
            assignedTo,
            setAssignedTo,
            assignToUserModal,
            setAssignToUserModal,
            handleNext,
          }}
        />
      )}
      {showSurveyForm && (
        <SurveyFormModal
          {...{
            surveyForm,
            assignedTo,
            setAssignedTo,
            getAllSurveys,
            showSurveyForm,
            setShowSurveyForm,
          }}
        />
      )}
      {completedSurveyLoading && (
        <CompletedSurveyListModal
          {...{
            completedSurveys,
            setCompletedSurveys,
            handleViewSurveyResponse,
            setCompletedSurveyLoading,
          }}
        />
      )}
      {userSubmittedSurvey && (
        <UserSurveyAnswersModal
          {...{
            userSubmittedSurvey,
            userAnswer,
            setUserSubmittedSurvey,
            userFreeResponseAnswers,
            setUserFreeResponseAnswers,
            setCompletedSurveyLoading,
          }}
        />
      )}
      {surveyAggregateLoading && (
        <UserSurveyAggregateModal
          {...{
            userSurveyAggregate,
            setUserSurveyAggregate,
            setSurveyAggregateLoading,
          }}
        />
      )}

      {submitSurveyModal && (
        <SubmitSurveyModal
          {...{
            submitSurveyModal,
            setSubmitSurveyModal,
            handleSubmitSurveyAnswer,
            submitSurveyLoading,
          }}
        />
      )}
    </>
  );
};

export default Survey;
