import styled from "styled-components";

export const DashboardOuterContainer = styled.div``;
export const DashboardInnerContainer = styled.div``;
export const DashboardUpperRow = styled.div``;
export const Boxes = styled.div`
  display: flex;
  gap: 20px;
`;
export const Box = styled.div`
  border-radius: 10px;
  border: 1px solid #dbdbdb;
  background: #fff;
  box-shadow: 0px 3.143px 31.429px 0px rgba(182, 174, 174, 0.25);
  min-width: 240px;
  max-width: 275px;
  width: 100%;
`;
export const Info = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px;
`;
export const IconWrapper = styled.div`
  border-radius: 16px;
  background: rgba(68, 68, 155, 0.1);
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const Description = styled.div`
  padding-left: 12px;
`;
export const DescriptionTitle = styled.h3`
  color: #302f2f;
  font-family: "Nunito Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 3px 0 8px;
`;
export const DescriptionValue = styled.p`
  color: #302f2f;
  font-family: "Nunito Sans";
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
`;
export const BoxBottom = styled.div`
  border-top: 1px solid #dbdbdb;
`;
export const BottomInfo = styled.p`
  margin: 0;
  color: #302f2f;
  font-family: "Nunito Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  padding: 8px;
`;
export const DashboardBottomRow = styled.div``;
export const InfoLeft = styled.div`
  display: flex;
  align-items: flex-start;
`;
export const Statistics = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 18px;
  margin-top: 24px;
`;
export const MatchedBox = styled.div`
  border-radius: 10px;
  background: #fff;
  border: 1px solid #dbdbdb;
  max-width: 30%;
  width: 100%;
  padding: 12px;
  min-height: 320px;
`;
export const BoxTop = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const BoxTitle = styled.h3`
  color: #302f2f;
  font-family: "Nunito Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
`;
export const ViewMatches = styled.div`
  cursor: pointer;
  border-radius: 3px;
  background: rgba(79, 68, 155, 0.1);
  padding: 5px 8px;
  color: #4f449b;
  font-family: "Nunito Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;
export const Graph = styled.div`
  display: flex;
  justify-content: center;
`;
export const MeetingsConducted = styled.div`
  border-radius: 10px;
  background: #fff;
  border: 1px solid #dbdbdb;
  max-width: 30%;
  width: 100%;
  padding: 12px;
  min-height: 320px;
`;
export const Dropdown = styled.div``;
export const AverageMeetings = styled.div`
  border-radius: 10px;
  background: #fff;
  border: 1px solid #dbdbdb;
  max-width: 30%;
  width: 100%;
  padding: 12px;
  min-height: 320px;
`;
export const Input = styled.div``;
export const Applicants = styled.div`
  border-radius: 10px;
  background: #fff;
  border: 1px solid #dbdbdb;
  max-width: 30%;
  width: 100%;
  padding: 12px;
  min-height: 320px;
`;
export const ActiveUsers = styled.div`
  border-radius: 10px;
  background: #fff;
  border: 1px solid #dbdbdb;
  max-width: 30%;
  width: 100%;
  padding: 12px;
  min-height: 320px;
`;
export const BoxInner = styled.div``;
export const BoxDescription = styled.p`
  color: #b3b3b3;
  font-family: "Nunito Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin: 6px 0 0;
`;
export const Download = styled.div`
  border-radius: 3px;
  border: 1px solid #ededed;
  padding: 5px 4px;
  height: 30px;
  width: 96px;
  color: #333232;
  font-family: "Nunito Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  cursor: pointer;
`;
export const Invite = styled.div`
  cursor: pointer;
  border-radius: 3px;
  border: 1.2px solid #44449b;
  color: #302f2f;
  font-family: "Nunito Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 70px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const User = styled.div`
  padding: 0 12px;
  width: 25%;
  margin-top: 25px;
  position: relative;
`;
export const ImageWrapper = styled.div`
  width: 95px;
  height: 95px;
  border-radius: 10px;
`;
export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;
export const UserTag = styled.div`
  width: 65px;
  height: 45px;
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  bottom: -22px;
  left: 25px;
  border-radius: 4px;
  box-shadow: 0px 3.143px 31.429px 0px rgba(182, 174, 174, 0.25);
`;
export const MostActiveUsers = styled.div`
  border-radius: 10px;
  background: #fff;
  border: 1px solid #dbdbdb;
  max-width: 30%;
  width: 100%;
  padding: 12px;
  min-height: 320px;
`;
export const Number = styled.div`
  color: #302f2f;
  font-family: "Nunito Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;
export const Name = styled.div`
  color: #302f2f;
  font-family: "Nunito Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const Table = styled.div``;

export const Users = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 18px;
`;

export const MatchChartOuter = styled.div`
  display: flex;
  flex-direction: row;
  width: 100% !important;
  gap: 75px;
  padding-left: 30px;
  min-height: 200px;
`;

export const CustomLegendContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 15px;
  gap: 12px;
`;

export const CustomLegendInner = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${(props) => (props.index < props.data - 1 ? "20px" : "0")};
  font-family: "Nunito Sans";
  font-weight: "500";
  font-size: 14px;
`;

export const ColorDot = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 8px;
`;

export const CustomLegendValue = styled.span`
  color: #70708c;
  font-family: "Nunito Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const MeetingsConductedChartOuter = styled.div`
  height: 250px;
  width: 100%;
  margin-top: 53px;
  display: flex;
  margin-top: 15px;
  gap: 60px;
  padding-left: 30px;
`;

export const CustomToolTipContent = styled.div`
  padding: 6px 8px;
  max-width: 114px;
  width: 100%;
  min-height: 84px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-radius: 10px;
  background: #fff;
  border: 1px solid #dbdbdb;
`;

export const CustomToolTipText = styled.p`
  color: #302f2f;
  font-family: "Nunito Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin: 0;
`;

export const CustomToolTipBoldText = styled.span`
  color: #302f2f;
  font-family: "Nunito Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

export const ResponseTextDiv = styled.div`
  display: flex;
  align-items: center;
`;

export const NoResponseText = styled.div`
  width: 80%;
  text-align: center;
  display: flex;
  align-items: center;
`;
