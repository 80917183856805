import React from "react";
import { useNavigate } from "react-router-dom";
import callAPI from "../../../../api/apiCaller";
import { formatMessageDate } from "../../../../common/utils";
import { Context } from "../../../../Store";
import { getNotifications } from "../../../../Store/Notifications/accessors";
import {
  markAsRead,
  updateWithNewNotifications,
} from "../../../../Store/Notifications/actions";
import { getUserInfo } from "../../../../Store/User/accessors";
import {
  NotificationsBox,
  Notification,
  NotificationImage,
  NotificationBox,
  NotificationHeader,
  NotificationTitle,
  NotificationTime,
  NotificationContent,
  ButtonContainer,
} from "./styles";
import Button from "../../../../common/Button";
import { ButtonSpacer } from "../../../../common/Button/styles";
import Loading from "../../../../common/Loading";
import { socket } from "../../../../common/chat-socket";


function Notifications({ isOpen, setIsOpen }) {
  const { state, dispatch } = React.useContext(Context);
  const [page, setPage] = React.useState(2); // Current page number
  const [pageSize, setPageSize] = React.useState(20); // Number of items per page
  const [loading, setLoading] = React.useState(false);
  const [shouldLoadNotifications, setShouldLoadNotifications] =
  React.useState(false);

  const handleScroll = async (e) => {
    const { scrollTop } = e.target;
    if (scrollTop  &&  !loading) {
      setLoading(true);
      setPage(prevPage => prevPage + 1); // Update page using a callback
        callAPI(dispatch, "getNotifications", {
            userId: getUserInfo(state).id,
            page: page, // Use updated page value
            take: pageSize,
        }).then((res) => {
            dispatch(updateWithNewNotifications(notificationWithTimeProperty(res.data)));
            setLoading(false);
        });
    }
};

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadNotifications = () => {
    callAPI(dispatch, "getNotifications", {
      userId: getUserInfo(state).id,
      page: 1, // Include page parameter
      take: +pageSize,
    }).then((res) => {
      dispatch(updateWithNewNotifications(notificationWithTimeProperty(res.data)));
      setShouldLoadNotifications(false);
    });
  };

  React.useEffect(() => {
    // initial render
    loadNotifications();

    // socket notification
    socket.on("notifications", ((id) => {
      if(id.userId === getUserInfo(state).id) {
        loadNotifications()
      };
    }));
  },[]);

  React.useEffect(() => {
    if(shouldLoadNotifications){
      loadNotifications();
    }
  }, [shouldLoadNotifications]);

    const notificationWithTimeProperty = (notifications) => {
      const notificationPayload = [];
      notifications.forEach((notification) => {
        const dateObject = new Date(notification.createdAt);
        const unixTimestamp = Math.floor(dateObject.getTime());
        notification["time"] = unixTimestamp;
        notificationPayload.push(notification);
      });
      return notificationPayload;
    };

    const LinkNavigation=(notification)=>{
     if(notification.programId){
       window.location.href = `${window.location.protocol}//${window.location.host}/app/programs/${notification?.programId}/home`;
     }
     else return;
    }
  return (
    <>
      <NotificationsBox isOpen={isOpen} onScroll={handleScroll} >
        {getNotifications(state)
          .sort((a, b) => b.time - a.time)
          .map((notification) => {  
            // console.log(notification);          
            return (
              <>
              {notification.type === "trackhourrequest" ? 
              <Notification
                key={notification.id}
                isOpen={isOpen}
                hasRead={notification.isRead}
              >
                <NotificationImage
                  src={
                    notification.program?.image
                      ? notification.program.image
                      : notification?.user?.image
                      ? notification.user.image
                      : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                  }
                />
                <NotificationBox>
                  <NotificationHeader>
                    <NotificationTitle>
                      {notification.type === "trackhourrequest"
                        ? "Timesheet Request"
                        : notification.type}
                    </NotificationTitle>
                    <NotificationTime>
                      {formatMessageDate(notification.createdAt)}
                    </NotificationTime>
                  </NotificationHeader>
                  <NotificationContent>
                    {notification.message}
                  </NotificationContent>
                  {notification.type === "trackhourrequest" && (
                    <NotificationContent>
                      <ButtonContainer>
                        <Button
                          onClick={() => {
                            callAPI(dispatch, "approveTrackHours", {
                              ...notification,
                            }).then((res) => {
                              if(res?.isResponseOk){
                                setShouldLoadNotifications(true);
                              }
                              dispatch(markAsRead(notification.id));
                              setIsOpen(false);
                              callAPI(dispatch, "markNotificationAsRead", {
                                id: notification.id,
                              }).then((res) => {
                                if(res?.isResponseOk){
                                  setShouldLoadNotifications(true);
                                  LinkNavigation(notification)
                                }
                              });
                            });
                          }}
                          // icon={isApprovingRecord===row.id ? "loading":"tick"}
                          icon={"tick"}
                        />
                        <ButtonSpacer />
                        <Button
                          onClick={() => {
                            callAPI(dispatch, "deleteTrackHours", {
                              notification,
                            }).then(() => {});
                            dispatch(markAsRead(notification.id));
                            setIsOpen(false);
                            callAPI(dispatch, "markNotificationAsRead", {
                              id: notification.id,
                            }).then((res) => {
                              if(res?.isResponseOk){
                                setShouldLoadNotifications(true);
                                LinkNavigation(notification)
                              }
                            });
                          }}
                          icon={"trash"}
                        />
                      </ButtonContainer>
                    </NotificationContent>
                  )}
                </NotificationBox>
              </Notification>
              :           
              <Notification
                key={notification.id}
                isOpen={isOpen}
                hasRead={notification.isRead}
                onClick={(e) => {
                  dispatch(markAsRead(notification.id));
                  setIsOpen(false);
                  callAPI(dispatch, "markNotificationAsRead", {
                    id: notification.id,
                  }).then((res) => {
                    if(res?.isResponseOk){
                      setShouldLoadNotifications(true);
                      LinkNavigation(notification)
                    }
                  });
                }}
              >
                <NotificationImage
                  src={
                    notification.program?.image
                      ? notification.program.image
                      : notification?.user?.image
                      ? notification.user.image
                      : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                  }
                />
                <NotificationBox>
                  <NotificationHeader>
                    <NotificationTitle>
                      {notification.type === "trackhourrequest"
                        ? "Timesheet Request"
                        : notification.type}
                    </NotificationTitle>
                    <NotificationTime>
                      {formatMessageDate(notification.createdAt)}
                    </NotificationTime>
                  </NotificationHeader>
                  <NotificationContent>
                    {notification.message}
                  </NotificationContent>
                </NotificationBox>
              </Notification>
              }
              </>);
          })}
          {loading && <Loading loadingItem="notification" isLoading={true}/>}
      </NotificationsBox>
    </>
  );
}
export default Notifications;
