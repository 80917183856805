import React from "react";
import { useParams } from "react-router-dom";
import callAPI from "../../../../../api/apiCaller";
import { ButtonSpacer } from "../../../../../common/Button/styles";
import Checkbox from "../../../../../common/Checkbox";
import CheckboxGroup from "../../../../../common/CheckboxGroup";
import Labelize from "../../../../../common/Labelize";
import Loading from "../../../../../common/Loading";
import Modal from "../../../../../common/Modal";
import TextInput from "../../../../../common/TextInput";
import {
  createInitialValid,
  timeToDateValue,
  updateValid,
  updateValidTogether,
  validateDate,
  validateIsValid,
  validateText,
} from "../../../../../common/utils";
import { AddEditTaskContainer } from "./styles";
import { Context } from "../../../../../Store";
import ComboBox from "../../../../../common/ComboBox";

import { AddEditModalHeader, InputTitle } from "./styles.js";

function AddEditTask({
  programType,
  editTask,
  setShowAddEditTask,
  getCurrentProgramTask,
  setOpenDeleteModal
}) {
  const isEditTask = typeof editTask !== "boolean";
  const params = useParams();
  const [task, setTask] = React.useState(null);
  const [isSavingTask, setIsSavingTask] = React.useState(false);
  const options = [{ options1: "options1 " }, { options1: "option2" }];
  const [isValid, setIsValid] = React.useState(
    createInitialValid(["title", "dueDate", "description"])
  );
  const { state, dispatch } = React.useContext(Context);

  // function getMyTeam() {
  //   callAPI(dispatch, "getMyteams", {
  //     id: params.selectedProgram,
  //   }).then((res) => {

  //     setTeam(res?.data.map((team)=>{

  //       return { title: team.name, id: team.id}
  //     }));
  //     !isEditTask &&
  //     setTask({
  //       title: "",
  //       dueDate: "",
  //       description: "",
  //       // assignTo: [],
  //       reminder:[],
  //       programId:+params.selectedProgram,
  //       teamId: res.data[0]?.id ?res.data[0].id :"",
  //     })
  //   });
  // }
  React.useEffect(() => {
    if (isEditTask) {
      // if(programType==='TEAM'){getMyTeam()}
      // setTimeout(()=>{},2000)
      callAPI(dispatch, "getTask", {
        id: params.selectedProgram,
        rid: editTask,
      }).then((res) => {
        res.data.reminder = [res.data.reminder];
        // res.data.dueDate=timeToDateValue(res.data.dueDate)

        setTask(res.data);

        updateValidTogether(isValid, setIsValid, res.data, {
          title: validateText,
          dueDate: validateDate,
          description: validateText,
          // assignTo:validateArray()
        });
      });
    } else {
      setTask({
        title: "",
        dueDate: "",
        description: "",
        // assignTo: [],
        reminder: [],
        programId: +params.selectedProgram,
      });
    }
  }, []);

  function saveTask() {
    setIsSavingTask(true);
    if (isEditTask) {
      task.reminder = task.reminder[0] ? task.reminder[0] : "";
      delete task.teamTask;
      callAPI(dispatch, "updateTask", { id: task.id, ...task }).then(
        (response) => {
          setIsSavingTask(false);
          setShowAddEditTask(false);
          getCurrentProgramTask();
        }
      );
    } else {
      task.reminder = task.reminder[0] ? task.reminder[0] : "";
      callAPI(dispatch, "saveTask", task).then((response) => {
        setIsSavingTask(false);
        setShowAddEditTask(false);
        getCurrentProgramTask();
      });
    }
  }
  if (task === null && isEditTask) {
    return (
      <Modal
        title={
          <AddEditModalHeader>
            {isEditTask ? "Edit Task" : "Add Task"}
          </AddEditModalHeader>
        }
      >
        <Loading loadingItem="Task" />
      </Modal>
    );
  }
  return (
    <Modal
      className="custom-modal layout-styles"
      width="590px"
      noPadding={true}
      paddingBottom={true}
      isTask
      title={
        <AddEditModalHeader>
          {isEditTask ? "Edit Task" : "Add Task"}
        </AddEditModalHeader>
      }
      actionClass={`${isEditTask ? "actionbtns3" : ""}`}
      onClose={() => {
        setShowAddEditTask(false);
      }}
      actions={
        isEditTask
          ? [
              {
                text: "Delete",
                icon: "delete",
                variant: "pending",
                isNotRound: "5px",
                textColor: true,
                onClick: () => {
                  setOpenDeleteModal(true);
                  setShowAddEditTask(false);
                },
              },
              {
                text: "Cancel",
                // icon: "cross",
                variant: "pending",
                isNotRound: "5px",
                textColor: true,
                onClick: () => {
                  setShowAddEditTask(false);
                },
              },
              {
                text: isEditTask ? "Save" : "Add",
                icon: isSavingTask ? "loading" : isEditTask ? "save" : "add",
                disabled: !validateIsValid(isValid),
                variant: "Save",
                isNotRound: "5px",
                onClick: () => {
                  saveTask();
                },
              },
            ]
          : [
              {
                text: "Cancel",
                // icon: "cross",
                variant: "pending",
                isNotRound: "5px",
                textColor: true,
                onClick: () => {
                  setShowAddEditTask(false);
                },
              },
              {
                text: isEditTask ? "Save" : "Add",
                icon: isSavingTask ? "loading" : isEditTask ? "save" : "add",
                disabled: !validateIsValid(isValid),
                variant: "Completed",
                isNotRound: "5px",
                onClick: () => {
                  saveTask();
                },
              },
            ]
      }
    >
      <AddEditTaskContainer>
        <Labelize label={<InputTitle>Title</InputTitle>}>
          <TextInput
            borderRadius="5px"
            maxWidth="100%;"
            isTask
            value={task?.title}
            setValue={(newValue) => {
              setTask({
                ...task,
                title: newValue,
              });
            }}
            isValid={isValid.title}
            validator={validateText}
            setIsValid={updateValid(isValid, setIsValid, "title")}
          />
        </Labelize>

        {/* {teams && teams.length>0 &&<Labelize label="Select Team">
       <ComboBox
                  placeholder={"select team"}
                  options={teams.length && teams}
                  // validator={(newValue) => element.validator(newValue, meetingRequests.form)}
                  // hideInstruction={element.hideInstruction}
                  // type={element.type}
                  value={task.teamId ? task.teamId : false}
                  setValue={(newValue) => {
                    console.log("new team id ", newValue)
          setTask({
            ...task,
            teamId: newValue
          })
        }} 
                />
      </Labelize>
      } */}

        <ButtonSpacer />
        <Labelize label={<InputTitle>About</InputTitle>}>
          <TextInput
            value={task?.description}
            setValue={(newValue) => {
              setTask({
                ...task,
                description: newValue,
              });
            }}
            isTextArea
            isTask
            isHeight={true}
            isValid={isValid.description}
            validator={validateText}
            setIsValid={updateValid(isValid, setIsValid, "description")}
            backgroundColor="transparent"
          />
        </Labelize>
        <ButtonSpacer />
        <Labelize label={<InputTitle>Due Date</InputTitle>}>
          <TextInput
            borderRadius="5px"
            maxWidth="100%;"
            value={task?.dueDate}
            type="date"
            isTask
            setValue={(newValue) => {
              setTask({
                ...task,
                dueDate: newValue,
              });
            }}
            isValid={isValid.dueDate}
            validator={validateDate}
            setIsValid={updateValid(isValid, setIsValid, "dueDate")}
          />
        </Labelize>
        <ButtonSpacer />
        <ButtonSpacer />
        {task && task?.reminder && (
          <CheckboxGroup
            label={<InputTitle>Remind User</InputTitle>}
            value={task.reminder}
            setValue={(newValue) => {
              console.log("new value", newValue);
              setTask({
                ...task,
                reminder: newValue,
              });
            }}
            options={[
              {
                label: "A day before",
                value: "1 day",
              },
              {
                label: "A week before",
                value: "1 week",
              },
            ]}
          />
        )}
      </AddEditTaskContainer>
    </Modal>
  );
}
export default AddEditTask;
