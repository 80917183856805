import React, { useState } from "react";
import Icon from "../../../../../../common/Icon";
import {
  AvailableNowSurveyButton,
  CloseButton,
  ModalCloseHeader,
  ModalHeader,
  ModalOverlay,
  ModalTitle,
  SurveyCard,
  SurveyFormContainer,
  SurveyModalWrapper,
  SurveyWrapper,
  SurveyListLoader,
  NoRcordFound,
  NoRecordFound
} from "../styles.js";
import callAPI from "../../../../../../api/apiCaller.js";
import SurveyLoader from "../components/SurveysLoader.js";
import { Context } from "../../../../../../Store/index.js";
import SubmitSurveyModal from "./SubmitSurveyModal.js";
import Loading from "../../../../../../common/Loading/index.js";

const SurveyListModal = ({ programId, setOpenSurveyListModal }) => {
  const { state, dispatch } = React.useContext(Context);
  const [submitSurveyModal, setSubmitSurveyModal] = useState(null);
  const [submitSurveyLoading, setSubmitSurveyLoading] = useState(false);
  const [surveys, setSurveys] = useState(null);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(4);
  const [count, setCount] = useState(10);
  const [loading, setLoading] = useState(false);

  const getAllSurveys = () => {
    
    callAPI(dispatch, "getAllSurveys", { page, limit, programId }).then(
      (res) => {
        
        setSurveys(res?.data || []);
        setCount(res?.total || 0);
      }
    );
  };

  React.useEffect(() => {
    getAllSurveys();
  }, [limit, page]);

  const handleCloseModal = () => {
    setSurveys(null);
    setOpenSurveyListModal(false);
  };

  const showFormattedDate = (date) => {
    return new Date(date).toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const handleSubmitSurveyAnswer = (payload) => {
    setSubmitSurveyLoading(true);
    callAPI(dispatch, "submitSurvey", payload).then((res) => {
      
      getAllSurveys();
      setSubmitSurveyLoading(false);
      setSubmitSurveyModal(false);
    });
  };

  const handleScroll = async (e) => {
    const scrollTop = e.target.scrollTop;
    const scrollHeight = e.target.scrollHeight;
    const clientHeight = e.target.clientHeight;
    if (
      scrollTop + clientHeight + 3 >= scrollHeight &&
      !loading &&
      page < Math.round(count / limit)
    ) {
      setLoading(true);
      setPage((prevPage) => prevPage + 1);
      callAPI(dispatch, "getAllSurveys", {
        page: page,
        limit: limit,
      }).then((res) => {
        setSurveys((prev) => [...prev, ...res?.data] || []);
        setLoading(false);
      });
    }
  };
  const isSurveyClosed =(survey)=>  new Date(survey?.endedOn) <= new Date()

  return (
    <>
      <ModalOverlay>
        <SurveyFormContainer>
          {/* header */}
          <ModalHeader>
            <ModalTitle>Active Surveys</ModalTitle>
            <ModalCloseHeader>
              <CloseButton onClick={handleCloseModal}>
                <span>
                  <Icon type="close"></Icon>
                </span>
              </CloseButton>
            </ModalCloseHeader>
          </ModalHeader>
          {/* Survey List */}
          {!surveys ? (
            <SurveyLoader text={"Surveys"} />
          ) : (
            <>
            {!surveys?.length ? <NoRecordFound>No Active Survey Found ...!</NoRecordFound>:  
              <SurveyModalWrapper onScroll={handleScroll}>
                {surveys.map((survey) => (
                  <SurveyCard key={survey.id}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <h3>{survey.name}</h3>
                        <h5 style={{ marginTop: "15px", color:isSurveyClosed(survey)?'red':'#3DAE1A'}}>
                          Due On: {showFormattedDate(survey.endedOn)}
                        </h5>
                        <span style={{ marginTop: "-15px" }}>
                          Status :{" "}
                          <span>
                            {isSurveyClosed(survey) ? "Closed" : survey?.userSubmittedSurvey

                              ? "Completed"
                              : "Pending"}{" "}
                          </span>
                        </span>
                      </div>
                      <div style={{ position: "relative" }}>
                        {!survey?.userSubmittedSurvey && !isSurveyClosed(survey) && (
                          <AvailableNowSurveyButton
                            onClick={() => setSubmitSurveyModal(survey)}
                          >
                            Available Now
                          </AvailableNowSurveyButton>
                        )}
                      </div>
                    </div>
                  </SurveyCard>
                ))}
                {loading && (
                  <SurveyListLoader>
                    <Loading loadingItem="Surveys" />
                  </SurveyListLoader>
                )}
              </SurveyModalWrapper>
              }
            </>
          )}

          {/* Survey List */}
        </SurveyFormContainer>
      </ModalOverlay>

      {submitSurveyModal && (
        <SubmitSurveyModal
          {...{
            submitSurveyModal,
            setSubmitSurveyModal,
            handleSubmitSurveyAnswer,
            submitSurveyLoading,
          }}
        />
      )}
    </>
  );
};

export default SurveyListModal;
