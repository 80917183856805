import React, { useState } from "react";
import { PillsContainer } from "../ProgramConnections/ProgramConnection/ConnectionProfile/styles";
import Pills from "../../../../../common/Pills";
import ProgramTasks from "../ProgramTasks";
import Survey from "../ProgramSurveys";
import {
  TaskContainerOuter
} from './styles';

const Index = () => {
  const [currentTab, setCurrentTab] = useState("Tasks");
  return (
    <TaskContainerOuter>
      <PillsContainer meetings={true} isConnection={true}>
        <Pills
          meetings={true}
          isWidth={true}
          value={currentTab}
          setValue={setCurrentTab}
          options={[
            {
              label: "Tasks",
              value: "Tasks",
            },
            {
              label: "Survey",
              value: "Survey",
            },
          ]}
        />
      </PillsContainer>
      {currentTab === "Tasks" ? <ProgramTasks /> : <Survey />}
    </TaskContainerOuter>
  );
};

export default Index;
