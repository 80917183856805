import styled from "styled-components";
import { Scrollable } from "../../../../../common/styles";

export const ProgramTasksContainer = styled.div`
    margin: 0 10px;
    height: calc(100vh - 255px);
    @media (max-width:1600px){
      height: calc(100vh - 240px);
    }
`;

export const TasksActions = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom:20px;
`;

export const TaskWrapper = styled.div`
  margin: 20px 0;
  border: 5px solid #f00;
`;

export const TaskCard = styled.div`
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 10px;
`;

export const NoTaskContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const NoTaskInner = styled.div`
  border-radius: 20px;
  background: #FFF;
  box-shadow: 0px 3.143px 31.429px 0px rgba(182, 174, 174, 0.25);
  padding: 40px 120px;
`;
export const NoTaskImageWrapper = styled.div`
`;

export const Image = styled.img`
`;
export const NoTaskTitle = styled.h3`
  color: #302F2F;
  text-align: center;
  font-family: "Nunito Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
`;

export const NoTaskDescription = styled.p`
  color: #A8A5A5;
  font-family: "Nunito Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin:  0;
`;
export const NoTaskButtons = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 24px;
`;

export const CreateSurvey = styled.div`
  border-radius: 5px;
  background: rgba(77, 77, 159, 0.10);
  height: 40px;
  padding: 5px 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #4D4D9F;
  font-family: "Nunito Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
`;
export const CreateTask = styled.div`
  border-radius: 5px;
  background: #4D4D9F;
  cursor: pointer;
  font-family: "Nunito Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  padding: 5px 8px;
`;


export const DeleteTaskContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export const DeleteTaskTitle = styled.h3`
  color: #302F2F;
  font-family: "Nunito Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
`;
export const DeleteTaskInfo = styled.p`
  color: #757575;
  font-family: "Nunito Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 32px 0 0;
`;

export const TasksContainerInner = styled.div`
  ${Scrollable}
  height: 65vh;
  overflow-x: hidden;

  @media (max-width:1600px){
    height: 54vh;
  }
`;

export const TaskAccordWrapper = styled.div`
  background: #FFF;
  box-shadow: 0px 4px 50px 0px rgba(151, 140, 140, 0.20);
  padding: 10px;
`;