import React from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import {
  CustomToolTipContent,
  CustomToolTipText,
  MeetingsConductedChartOuter,
  NoResponseText,
  ResponseTextDiv,
} from "./styles";

const CustomTooltip = ({ label }) => {
  return (
    <CustomToolTipContent>
      <CustomToolTipText>{label}</CustomToolTipText>
    </CustomToolTipContent>
  );
};

const BarChartComponent = ({ question }) => {
  // const question = {
  //   aggregate: {
  //       "2": 0,
  //       "3": 1,
  //       "11": 0
  //   },
  //   question: "Type Your Question Here ...!1"
  // }
  const data = Object.keys(question.aggregate).map((key) => ({
    name: key,
    value: question.aggregate[key] || 0,
  }));
  const totalAnswers = Object.keys(question.aggregate).reduce(
    (m, c) => m + question.aggregate[c],
    0
  );

  return (
    <MeetingsConductedChartOuter>
      <ResponseTextDiv>
        <span>{totalAnswers} responses</span>
      </ResponseTextDiv>
      {totalAnswers < 1 ? (
        <NoResponseText>No response submitted yet</NoResponseText>
      ) : (
        <ResponsiveContainer width="60%" height="100%">
          <BarChart
            width={500}
            height={300}
            data={data}
            margin={{ top: 0, right: 0, left: -32, bottom: 0 }}
          >
            <CartesianGrid vertical={false} />
            <XAxis
              dataKey="name"
              tick={{
                fontSize: 14,
                fill: "#302F2F",
                fontWeight: "400",
                fontStyle: "normal",
              }}
            />
            <YAxis
              axisLine={false}
              tickLine={false}
              tick={{
                fontSize: 14,
                fill: "#302F2F",
                fontWeight: "400",
                fontStyle: "normal",
              }}
            />
            <Tooltip content={<CustomTooltip />} />
            <Bar
              className="meetings-conducted-bar"
              dataKey="value"
              barSize={20}
              fill="#4D4D9F"
              radius={[5, 5, 5, 5]}
            />
          </BarChart>
        </ResponsiveContainer>
      )}
    </MeetingsConductedChartOuter>
  );
};

export default BarChartComponent;
