import React, { useState } from "react";
import {
  SurveyWrapper,
  SurveyCard,
  DropIcon,
  DropdownItems,
  DropdownButton,
} from "../styles.js";
import Icon from "../../../../../../common/Icon";
import { Context } from "../../../../../../Store/index.js";

const SurveysList = ({
  surveys,
  handleEditSurvey,
  onEditSurvey,
  handleDeleteSurvey,
  getAllSubmittedSurveys,
  getAggregateSurveyById,
  handleOpenSubmitSurveyModal,
}) => {
  const { state } = React.useContext(Context);
  const [dropdown, setDropdown] = useState(false);

  const statusHandler = (survey) => {
    // if displaying survey s created by current user then return the status related to ended date
    // if (survey.userId === state?.user?.id) {
      return new Date(survey?.endedOn) <= new Date()
        ? "Submission Closed"
        : "Open for Submission";
    // }
    // else return if user submitted the survey or not
    // const submited = survey?.userSurveys?.some(
    //   (s) => s?.surveyId === survey?.id && s.userId === state?.user?.id
    // );
    // return submited ? "Completed" : "Pending";
  };

  const handleDropdown = (e, survey) => {
    e.stopPropagation();
    setDropdown(dropdown === survey.id ? null : survey.id);
  };

  const showFormattedDate = (date) => {
    return new Date(date).toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const showFormattedDateAndTime = (date) => {
    return new Date(date).toLocaleString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true, // 12-hour format
      month: "numeric",
      day: "numeric",
      year: "numeric",
    });
  };
  
  return (
    <SurveyWrapper>
      {surveys.map((survey) => (
        <SurveyCard key={survey.id}>
          <div
            style={{ display: "flex", justifyContent: "space-between" }}
            onClick={() => setDropdown(false)}
          >
            <div>
              <h3>{survey.name}</h3>
              <h4>Created At: {showFormattedDateAndTime(survey.createdAt)}</h4>
              <h5 style={{color:new Date(survey?.endedOn) <= new Date() ? 'red': '#3DAE1A' }}>Due On: {showFormattedDate(survey.endedOn)}</h5>
              <span>
                Status : <span>{statusHandler(survey)} </span>
              </span>
            </div>
            <div style={{ position: "relative" }}>
              <DropIcon onClick={(e) => handleDropdown(e, survey)}>
                <Icon type="vertical-dots" />
              </DropIcon>

              {dropdown === survey.id && (
                <DropdownItems
                  style={{
                    position: "absolute",
                    left: "-15rem",
                    width: "max-content",
                  }}
                >
                  {survey.userId === state?.user?.id && (
                    <>
                      <DropdownButton
                        onClick={() => {
                          handleEditSurvey(survey);
                          setDropdown(false);
                        }}
                      >
                        <Icon type={"edit"} color="#302F2F" />
                        View/Edit Survey
                      </DropdownButton>

                      <DropdownButton
                        onClick={() => {
                          getAllSubmittedSurveys(survey);
                          setDropdown(false);
                        }}
                      >
                        <Icon type={"tick-square"} color="#302F2F" />
                        View Completed
                      </DropdownButton>

                      <DropdownButton
                        onClick={() => getAggregateSurveyById(survey)}
                      >
                        <Icon type={"charts"} color="#302F2F" />
                        View Aggregate Responses
                      </DropdownButton>

                      <DropdownButton
                        onClick={() => {
                          handleDeleteSurvey(survey);
                          setDropdown(false);
                        }}
                      >
                        <Icon type={"delete"} color="#302F2F" />
                        Delete
                      </DropdownButton>
                    </>
                  )}
                  {survey.userId !== state?.user?.id && (
                    <DropdownButton
                      onClick={() => {
                        handleOpenSubmitSurveyModal(survey);
                        setDropdown(false);
                      }}
                    >
                      <Icon type={"edit"} color="#302F2F" />
                      Submit Survey
                    </DropdownButton>
                  )}
                </DropdownItems>
              )}
            </div>
          </div>
        </SurveyCard>
      ))}
    </SurveyWrapper>
  );
};

export default SurveysList;
